import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Button, Col, Form, Modal, Row, Typography } from "antd";
import { useContext, useState } from "react";
import {
  UserDetails,
  onFederatedSignIn,
  signUp,
  validatePassword,
  verifyUserOtp,
} from "../cognito/cognitoUtils";
import { useNavigate } from "react-router";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineLinkedin } from "react-icons/ai";
import { UserContext } from "@idsk/ui-core-framework";
import { createSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

export const SignUpPage = () => {
  const form = Form.useFormInstance<UserDetails>();
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSignUp = async (values: UserDetails) => {
    form.validateFields();
    if (values.username && values.password) {
      signUp(values)
        .then(() => {
          navigate(
            `/confirm?${createSearchParams({
              username: Buffer.from(values.username).toString("base64"),
              purpose: "signup",
            })}`
          );
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "712px",
        height: "auto",
        backgroundImage: `url("/Login.svg")`,
        backgroundSize: "cover",
        width: "710px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Col span={15}>
        <div
          style={{
            fontWeight: "400",
            fontSize: "48px",
            color: "#191919",
            lineHeight: "56px",
            marginTop: "120px",
            textAlign: "center",
            fontFamily: "'Tenor Sans', sans-serif",
          }}
        >
          Let’s get started
        </div>

        <div
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#575757",
            lineHeight: "20px",
            textAlign: "center",
            fontFamily: "'Space Grotesk', serif",
          }}
        >
          Join us, engage, and let your expertise shine...
        </div>
      </Col>
      <Col
        span={14}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            width: "190px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("/googleSignup.svg")`,
            backgroundSize: "cover",
            height: "40px",
            visibility: "visible",
          }}
          onClick={() =>
            onFederatedSignIn(CognitoHostedUIIdentityProvider.Google, false)
          }
        ></Button>
        <IdskButton
          style={{
            width: "190px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#2465AF",
            backgroundImage: `url("/linkedinSignup.svg")`,
            backgroundSize: "cover",
            color: "#FFF",
            height: "40px",
            visibility: "visible",
          }}
          role="button"
          onClick={() => onFederatedSignIn("LinkedIn", true)}
          size="large"
        ></IdskButton>
      </Col>
      <Col span={16} style={{ textAlign: "center" }}>
        <Typography.Text>- OR -</Typography.Text>
      </Col>
      {error && (
        <Col span={14}>
          <Alert type="error" message={error} />
        </Col>
      )}

      <Col span={14}>
        <TextInput
          name="name"
          required
          label=""
          placeholder="Enter full name"
        />
      </Col>
      <Col span={14}>
        <TextInput
          name="username"
          label=""
          required
          placeholder="Enter email id"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        />
      </Col>
      <Col span={14}>
        <TextInput
          name="password"
          rules={[
            {
              required: true,
              message: "Email Address is required",
            },
            () => ({
              validator(_, password) {
                return validatePassword(password);
              },
            }),
          ]}
          label="Password"
          type={showPassword ? "text" : "password"}
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
            )
          }
          placeholder="Password"
        />
      </Col>

      <Col span={14}>
        <IdskButton
          style={{
            width: "100%",
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            height: "56px",
            background: "#0D6EFD",
            border: "2px solid",
            borderImageSource:
              "linear-gradient(180deg, #75ADFF 0%, #1D2FA1 100%)",
            borderImageSlice: 1,
          }}
          role="button"
          onClick={() => {
            onSignUp(form.getFieldValue([]));
          }}
          size="large"
        >
          Sign Up
        </IdskButton>
      </Col>
      <Col
        offset={7}
        span={12}
        style={{ marginTop: "1%", marginBottom: "24px" }}
      >
        <Typography.Text>
          Already have an account?{" "}
          <Typography.Link
            underline
            onClick={() => navigate("/login")}
            style={{
              color: "#0D6EFD",
              fontWeight: "500",
            }}
          >
            Sign In
          </Typography.Link>{" "}
        </Typography.Text>
      </Col>
    </Row>
  );
};
