import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Button, Col, Form, Row, Typography } from "antd";
import {
  UserDetails,
  onFederatedSignIn,
  signIn,
  validatePassword,
} from "../cognito/cognitoUtils";
import { useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { UserContext } from "@idsk/ui-core-framework";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { amplifyConfig } from "../cognito/cognitoconfig";
import { AiOutlineLinkedin } from "react-icons/ai";

export const SignInPage = () => {
  const navigate = useNavigate();
  const form = Form.useFormInstance();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setUser } = useContext(UserContext);

  const onSignIn = async (values: UserDetails) => {
    setError(undefined);
    setLoading(true);
    form.validateFields();
    if (values.password && values.username) {
      signIn(values, setUser!)
        .catch((error) => {
          if (error.message === "Password reset required for the user") {
            navigate("/forgot_password?purpose=reset_required");
          }
          setError(error.message);
        })
        .then((data: any) => {
          if (
            data.challengeName === "NEW_PASSWORD_REQUIRED" ||
            data.challengeName === "PASSWORD_VERIFIER"
          ) {
            navigate("/reset_password?purpose=new_password");
          } else {
            Auth.currentAuthenticatedUser().then((currentUser) => {
              setUser!(currentUser);
              localStorage.setItem(
                "idToken",
                `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
              );
            });
          }
        });
    }
    setLoading(false);
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "712px",
        backgroundImage: `url("/Login.svg")`,
        backgroundSize: "cover",
        width: "710px",
        backgroundRepeat: "no-repeat",
        height: "auto",
      }}
    >
      <Col span={15}>
        <div
          style={{
            fontWeight: "400",
            fontSize: "48px",
            color: "#191919",
            lineHeight: "56px",
            marginTop: "120px",
            textAlign: "center",
            fontFamily: "'Tenor Sans', sans-serif",
          }}
        >
          Welcome Champ!
        </div>

        <div
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#575757",
            lineHeight: "20px",
            textAlign: "center",
            fontFamily: "'Space Grotesk', serif",
          }}
        >
          Log in, Show up, and let your talent speak...
        </div>
      </Col>
      <Col
        span={14}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            width: "190px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("/google.svg")`,
            backgroundSize: "cover",
            height: "40px",
            visibility: "visible",
          }}
          onClick={() =>
            onFederatedSignIn(CognitoHostedUIIdentityProvider.Google, false)
          }
        ></Button>
        <IdskButton
          style={{
            width: "190px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#2465AF",
            backgroundImage: `url("/linkedin.svg")`,
            backgroundSize: "cover",
            color: "#FFF",
            height: "40px",
            visibility: "visible",
          }}
          role="button"
          onClick={() => onFederatedSignIn("LinkedIn", true)}
          size="large"
        ></IdskButton>
      </Col>
      <Col span={14} style={{ textAlign: "center" }}>
        <Typography.Text>- OR -</Typography.Text>
      </Col>
      {error && (
        <Col span={14}>
          <Alert type="error" message={error} />
        </Col>
      )}
      <Col span={14}>
        <TextInput
          type="email"
          name="username"
          required
          placeholder="Enter email id"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        />
      </Col>
      <Col span={14}>
        <TextInput
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
          type={showPassword ? "text" : "password"}
          placeholder="Enter password"
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
            )
          }
          formStyle={{ marginBottom: 0 }}
        />
      </Col>

      <Col span={14}>
        <IdskButton
          style={{
            width: "100%",
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            height: "56px",
            background: "#0D6EFD",
            border: "2px solid",
            borderImageSource:
              "linear-gradient(180deg, #75ADFF 0%, #1D2FA1 100%)",
            borderImageSlice: 1,
          }}
          role="button"
          onClick={() => {
            onSignIn(form.getFieldValue([]));
          }}
          size="large"
          loading={loading}
        >
          Sign In
        </IdskButton>

        <Button
          type="link"
          style={{ color: "#0D6EFD", padding: 0 }}
          onClick={() => {
            navigate(`/forgot_password`);
          }}
          className="idesk-forget-password"
        >
          Forgot Password?
        </Button>
      </Col>
      <Col
        offset={7}
        span={12}
        style={{ marginTop: "1%", marginBottom: "24px" }}
      >
        <Typography.Text>
          Don't have an account?{" "}
          <Typography.Link
            underline
            onClick={() => navigate("/signup")}
            style={{
              color: "#0D6EFD",
              fontWeight: "500",
            }}
          >
            Sign Up
          </Typography.Link>{" "}
        </Typography.Text>
      </Col>
    </Row>
  );
};
