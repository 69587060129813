import React, { useState } from "react";
import "./Header.scss";

interface ProfileDropdownProps {
  onSignOutClick: () => void;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  onSignOutClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="profile-container">
      <div className="profile-icon" onClick={toggleDropdown}>
        {/* Placeholder for Profile Image */}
        <div className="profile-image"></div>
      </div>

      {isOpen && (
        <div className="dropdown-menu">
          {/* <div className="profile-details">
            <div className="profile-name">James David</div>
            <div className="profile-email">jamesdeavid@gmail.com</div>
          </div>
          <hr className="divider" />
          <div className="dropdown-item">
            <span className="icon">⚙️</span> Settings
          </div> */}
          <div className="dropdown-item" onClick={onSignOutClick}>
            <span className="signOutIcon"></span> Sign Out
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
