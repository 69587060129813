import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Col, Form, Row, Typography } from "antd";
import React, { useState } from "react";
import { UserDetails, forgotPassword } from "../cognito/cognitoUtils";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";

export const ForgotPassword = () => {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const onSendOTP = (values: UserDetails) => {
    forgotPassword(values).then(() => {
      navigate(
        `/confirm?${createSearchParams({
          username: Buffer.from(values.username).toString("base64"),
          purpose: "forgot_password",
        })}`
      );
    });
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "712px",
        height: "auto",
        backgroundImage: `url("/Login.svg")`,
        backgroundSize: "cover",
        width: "710px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Col span={15}>
        <div
          style={{
            fontWeight: "400",
            fontSize: "48px",
            color: "#191919",
            lineHeight: "56px",
            marginTop: "120px",
            textAlign: "center",
            fontFamily: "'Tenor Sans', sans-serif",
          }}
        >
          Forgot Password
        </div>
        <div
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#575757",
            lineHeight: "20px",
            textAlign: "center",
            fontFamily: "'Space Grotesk', serif",
          }}
        >
          Nothing to worry, You can reset it here!
        </div>
        <div
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#191919",
            lineHeight: "20px",
            textAlign: "center",
            fontFamily: "'Space Grotesk', serif",
            marginTop: "48px",
          }}
        >
          We will sent an OTP to your registered mail id to reset your password.
        </div>
      </Col>
      {searchParams.get("purpose") === "reset_required" && (
        <Col span={14}>
          <Alert
            type="error"
            message={
              "Password reset required for user due to security reasons."
            }
          />
        </Col>
      )}
      {error && (
        <Col span={14}>
          <Alert type="error" message={error} />
        </Col>
      )}
      <Col span={14}>
        <TextInput
          name="username"
          required
          label="Email Address"
          placeholder="Enter mail id"
        />
      </Col>
      <Col span={14}>
        <IdskButton
          style={{
            width: "100%",
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            height: "56px",
            background: "#0D6EFD",
            border: "2px solid",
            borderImageSource:
              "linear-gradient(180deg, #75ADFF 0%, #1D2FA1 100%)",
            borderImageSlice: 1,
          }}
          onClick={() => {
            onSendOTP(form.getFieldValue([]));
          }}
          size="large"
        >
          Generate OTP
        </IdskButton>
      </Col>
      <Col
        offset={7}
        span={12}
        style={{ marginTop: "1%", marginBottom: "24px" }}
      >
        <Typography.Link
          underline
          onClick={() => navigate("/login")}
          style={{
            color: "#0D6EFD",
            fontWeight: "500",
          }}
        >
          Back to Sign In
        </Typography.Link>
      </Col>
    </Row>
  );
};
