import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { makeServer } from "./mirage/index";
import { getStageType } from "@idsk/ui-core-framework";
import "@idsk/components-assets/es5/theme.less";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "antd/dist/antd.css";

const environment = getStageType();
const queryClient = new QueryClient();

window.env = {
  STAGE: process.env.STAGE,
};
if (environment === "development") {
  makeServer();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
);
