import React from "react";
import { SideBarItem } from "@idsk/components-props";
import logo from "../assets//StintCodeLogo.icon";
import ProfileDropdown from "./ProfileDropdown";
import "./Header.scss";
import {
  DashboardOutlined,
  CarryOutOutlined,
  ReadOutlined,
  FilePdfOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

// Define the type for the profile object
type ProfileObject = {
  displayName: string;
  avatar: string;
};

// Define the type for the Header component props
interface HeaderProps {
  onSignOutClick: () => void;
  onSideBarItemClick: (sectionId: string, sideBarItem: SideBarItem) => void;
  items: Array<{
    id: string;
    title: string;
    data: SideBarItem[];
  }>;
  selectedSideBarTab: string;
  profile: ProfileObject;
  theme: any;
}

const Header: React.FC<HeaderProps> = ({
  onSignOutClick,
  onSideBarItemClick,
  items,
  selectedSideBarTab,
  profile,
  theme,
}) => {
  return (
    <div className="header-container">
      <div
        className="stintcode-logo-container"
        style={{
          backgroundImage: `url("/stintcodeLogo.svg")`,
        }}
      ></div>

      <div className="menu-items">
        {items.map((itemGroup) => (
          <div key={itemGroup.id}>
            {/* <h3>{itemGroup.title}</h3> */}
            {itemGroup.data.map((item) => (
              <div
                key={item.id}
                className={
                  selectedSideBarTab === item.id
                    ? "active-menu-item"
                    : "menu-item"
                }
                onClick={() => onSideBarItemClick(itemGroup.id, item)}
              >
                <item.icon />
                <span>{item.title}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* <button className="stintcode-signout-btn" onClick={onSignOutClick}>
        Sign Out
      </button> */}
      <ProfileDropdown onSignOutClick={onSignOutClick} />
    </div>
  );
};

export default Header;
