import { IdskSelectOptions } from "@idsk/components-props";

export const region = "ap-south-1";
export const mandatorySignIn = true;
export const scope = ["email", "openid", "profile"];
export const responseType = "code";

export const localhost = "localhost";
export const devAppLink = "dev-arena.interviewdesk.in";
export const preProdAppLink = "pp-arena.interviewdesk.in";
export const prodAppLink = "arena.interviewdesk.in";

export const dev = "development";
export const preprod = "preprod";
export const prod = "prod";

type UserPoolId = {
  dev: IdskSelectOptions;
  preprod: IdskSelectOptions;
  prod: IdskSelectOptions;
};

export const domainToUserPoolId: UserPoolId = {
  dev: {
    "localhost:3004": "ap-south-1_8jGrUzJtz",
    "dev-arena.interviewdesk.in": "ap-south-1_8jGrUzJtz",
    "dev.gallopwork.com": "ap-south-1_kbSqex0eI",
  },
  preprod: {
    "pp-arena.interviewdesk.in": "ap-south-1_vBf8vsNYd",
    "pp.gallopwork.com": "ap-south-1_vzfYUfLkk",
  },
  //Need to add for Production after First Deployment
  prod: {
    "arena.interviewdesk.in": "ap-south-1_2GLMnrTWF",
    "skills.aarushsoft.com": "ap-south-1_zWkgwHIWd",
  },
};
export const domainToUserPoolWebClientId = {
  dev: {
    "localhost:3004": "26fd34l8aje5aidcsqrq81qds2",
    "dev-arena.interviewdesk.in": "26fd34l8aje5aidcsqrq81qds2",
    "dev.gallopwork.com": "vht1b8vqhnn1mk772l796ulvj",
  },
  preprod: {
    "pp-arena.interviewdesk.in": "3k926e6iqkba1lf67gdj83uen1",
    "pp.gallopwork.com": "51ns1coofmhgjlve40cd76rqs5",
  },
  // Need to add for Production after First deployment
  prod: {
    "arena.interviewdesk.in": "60h41369mpsh1a6rkj7mh5s91v",
    "skills.aarushsoft.com": "2mhn2h03hp2d9e9cm42bnqdu8s",
  },
};

export const redirectBaseUrls = {
  dev: {
    "localhost:3004":
      "https://idsk-coreinfracdkdevwave-dev-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=26fd34l8aje5aidcsqrq81qds2&response_type=code&scope=email+openid+profile&redirect_uri=",
    "dev-arena.interviewdesk.in":
      "https://idsk-coreinfracdkdevwave-dev-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=26fd34l8aje5aidcsqrq81qds2&response_type=code&scope=email+openid+profile&redirect_uri=",
    "dev.gallopwork.com":
      "https://3p-aarushsoft-coreinfracdkdevwave-dev-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=vht1b8vqhnn1mk772l796ulvj&response_type=code&scope=email+openid+profile&redirect_uri=",
  },
  preprod: {
    "pp-arena.interviewdesk.in":
      "https://idsk-coreinfracdkpreprodwave-preprod-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=3k926e6iqkba1lf67gdj83uen1&response_type=code&scope=email+openid+profile&redirect_uri=",
    "pp.gallopwork.com":
      "https://3p-aarushsoft-coreinfracdkpreprodwave-preprod-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=51ns1coofmhgjlve40cd76rqs5&response_type=code&scope=email+openid+profile&redirect_uri=",
  },
  // Need to add for Production after First deployment
  prod: {
    "arena.interviewdesk.in":
      "https://idsk-coreinfracdkprodwave-prod-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=60h41369mpsh1a6rkj7mh5s91v&response_type=code&scope=email+openid+profile&redirect_uri=",
    "skills.aarushsoft.com":
      "https://3p-aarushsoft-coreinfracdkprodwave-prod-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=2mhn2h03hp2d9e9cm42bnqdu8s&response_type=code&scope=email+openid+profile&redirect_uri=",
  },
};

export const unAuthCognitoIdentityPoolIds = {
  [dev]: "ap-south-1:3e3b081e-8861-4f73-97f0-806d69134a94",
  [preprod]: "ap-south-1:75ac10ea-dcc6-4612-8ca2-23016241654b",
  [prod]: "ap-south-1:af2eac06-d4b5-48ae-8e6e-beecefa80275",
};
