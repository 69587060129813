import { AsyncGetUI, useAsyncGetUI } from "@idsk/components-ui";
import {
  UseListingResponseType,
  WebpAppType,
  getWebAppType,
  useListingQuery,
} from "@idsk/ui-core-framework";
import {
  ClientListResponse,
  UsePostToMultiSearchClientsDao,
} from "./ClientListing.dao";
import { bufferEncode } from "@idsk/components-props";

export interface ThemeWrapperProps {
  asyncSearchClientUI: AsyncGetUI<UseListingResponseType<ClientListResponse>>;
  children: JSX.Element;
}

export const ThemeWrapperDataFetcher = (props: {
  children: JSX.Element;
}): ThemeWrapperProps => {
  const domain = window.location.origin;

  const queryParams = {
    queryId: "client",
    domain: bufferEncode(domain),
  };
  const clientListingResponseData = useListingQuery<
    ClientListResponse,
    UsePostToMultiSearchClientsDao
  >(UsePostToMultiSearchClientsDao, 1, 10, queryParams);
  return {
    asyncSearchClientUI: useAsyncGetUI(clientListingResponseData),
    children: props.children,
  };
};
